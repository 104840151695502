<template>
  <b-modal
    :visible="show"
    ok-only
    ok-title="Accept"
    modal-class="modal-primary"
    centered
    title="Manage Category"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-form-group label="Category">
      <v-select
        v-model="form.category"
        placeholder="Category"
        taggable
        :options="categories.map(cat => cat.displayName)"
      />
    </b-form-group>
    <b-form-group label="Sub Category">
      <v-select
        v-model="form.subCategory"
        placeholder="Sub Category"
        taggable
        :options="subCategories.map(cat => cat.displayName)"
        @input="getDescription"
      />
    </b-form-group>
    <b-form-group label="Sub Category Description">
      <b-form-textarea
        v-model="form.description"
        placeholder="Sub Category Description"
      />
    </b-form-group>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="postJobCategory"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormTextarea, BSpinner, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import { reactive, toRefs, computed, getCurrentInstance } from 'vue'

export default {
  components: {
    BModal,
    vSelect,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const state = reactive({
      isProcessing: false,
      form: {
        category: null,
        subCategory: null,
        description: null,
      },
    })

    function postJobCategory() {
      const toSubmit = {
        category: {
          displayName: state.form.category,
        },
        subCategory: {
          displayName: state.form.subCategory,
          description: state.form.description,
        },
      }
      state.isProcessing = true
      useApollo.jobPortal.saveJobCategory(toSubmit).then(response => {
        root.showSuccess(response.data?.registerJobCategory?.status)
        root.$emit('close')
        root.$emit('refetch')
      }).finally(() => {
        state.isProcessing = false
      })
    }

    const subCategories = computed(() => {
      const category = props.categories.find(cat => cat.displayName === state.form.category)
      return category?.subCategories || []
    })

    function getDescription() {
      const subCategory = subCategories.value.find(sub => sub.displayName === state.form.subCategory)
      state.form.description = subCategory?.description
    }
    return {
      ...toRefs(state),
      postJobCategory,
      subCategories,
      getDescription,
    }
  },
}
</script>
